import axios from 'axios';

import { SortOrder } from 'constants/list';
import { CiboSubscription, SubscriptionStatus } from 'store/modules/backOfficeCiboSubscriptions/types';
import { CiboLocation } from 'store/modules/ciboLocations/types';
import { EnterpriseId } from 'types/models/enterprise';
import { APIResponseParsed } from 'types/response';
import { getRequest, patchRequest, postRequest } from 'utils/HttpRequest/http-request-utils';

import { parseAxiosError } from './utils/parse-axios-error';

// ==============================
// TYPES
// ==============================
type FetchAllCiboSubscriptionsParams = {
  enterpriseId: EnterpriseId;
  order: SortOrder;
};

type FetchAllCiboLocationsParams = {
  enterpriseId: EnterpriseId;
};

export interface CreateCiboSubscriptionDTO extends Pick<CiboSubscription, 'enterpriseId' | 'numberOfLocations'> { }

// ==============================
// REQUESTS
// ==============================
/** Must fetch 'all' to count the total zones purchased */
async function fetchAllCiboSubscriptions(
  params: FetchAllCiboSubscriptionsParams
): Promise<APIResponseParsed<CiboSubscription[]>> {
  try {
    const response = await getRequest<CiboSubscription[]>('integrations/cibolabs', { params });
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

/** Must fetch 'all' to count the total zones placed */
async function fetchAllCiboLocations(
  params: FetchAllCiboLocationsParams
): Promise<APIResponseParsed<CiboLocation[]>> {
  try {
    const response = await getRequest<CiboLocation[]>('integrations/cibolabs/locations', { params });
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function addCiboSubscription(
  data: CreateCiboSubscriptionDTO
): Promise<APIResponseParsed<CiboSubscription>> {
  try {
    const response = await postRequest<CiboSubscription, CreateCiboSubscriptionDTO>('integrations/cibolabs', data);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

async function cancelCiboSubscription(subscriptionId: number): Promise<APIResponseParsed<CiboSubscription>> {
  try {
    const response = await patchRequest<CiboSubscription, { status: SubscriptionStatus }>(
      `integrations/cibolabs/${subscriptionId}`,
      { status: 'cancelled' }
    );
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

// Grouping these functions for a namespace export, rather than named exports,
// to avoid naming collisions with action creators
export default {
  addCiboSubscription,
  cancelCiboSubscription,
  fetchAllCiboLocations,
  fetchAllCiboSubscriptions
};