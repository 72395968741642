import isNil from 'lodash/isNil';

import SamplePointStatistic from 'types/models/samplePointsStatistic';
import { DataType } from 'types/sample.enum';
import { convertMetricToImperialAlwaysRounding } from 'utils/convert-metric-to-imperial';
import { UnitType } from 'utils/get-unit-by-country';
import { centimetresToMetres } from 'utils/unit-conversions';

/** just a bit more precise than the usual 1 or 2 decimal places */
const PRECISION = 3;

type UnitTypeTrend = UnitType.TREND_SM | UnitType.TREND_MD;

export const getTankChangePerHour = (
  lastSample: NonNullable<SamplePointStatistic['lastSample']>,
  country: string,
  unitType: UnitTypeTrend = UnitType.TREND_SM
): number | undefined => {
  if (lastSample.dataType !== DataType.FORECAST) return undefined;

  const cmChangePerMinute: number | undefined = lastSample.extraValues.gradient;
  const cmChangePerHour: number | undefined = isNil(cmChangePerMinute) ? undefined : cmChangePerMinute * 60;

  if (isNil(cmChangePerHour)) return undefined;

  const changePerHourMetric: number = unitType === UnitType.TREND_MD
    ? centimetresToMetres(cmChangePerHour, PRECISION + 2)
    : cmChangePerHour;

  const changePerHourLocale: number | undefined = convertMetricToImperialAlwaysRounding(
    unitType,
    country,
    changePerHourMetric,
    PRECISION
  );

  return changePerHourLocale;
};