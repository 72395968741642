import { AxiosError } from 'axios';
import { action } from 'typesafe-actions';

import { SamplePointId } from 'types/models/samplePoint';
import Trigger, { TriggerEditableFields, TriggerId } from 'types/models/trigger';
import {
  ConditionType,
  EventLevel,
  TriggerType,
  ValueType
} from 'types/trigger.enum';

import ActionTypes from './constants';

export const setTriggers = (triggers: Record<TriggerId, Trigger>) =>
  action(ActionTypes.SET_TRIGGERS, triggers);

export const setTrigger = (_: { trigger: Trigger; specialKey: string }) =>
  action(ActionTypes.SET_TRIGGER, _);

export const removeTrigger = (_: {
  triggerId: TriggerId;
  specialKey: string;
}) => action(ActionTypes.REMOVE_TRIGGER, _);

export const removeTriggers = (_: {
  triggerIds: TriggerId[];
  specialKeys: string[];
}) => action(ActionTypes.REMOVE_TRIGGERS, _);

export const removeTriggerFailure = (message: string, error?: AxiosError) =>
  action(ActionTypes.REMOVE_TRIGGER_FAILURE, { message, error });

export const setPendingIsEnabledChange = (
  specialKey: string,
  values: Pick<
    Trigger,
    'duration' | 'message' | 'triggerType' | 'useForecast' | 'valueType'
  > & { value: number | null }
) => action(ActionTypes.SET_PENDING_IS_ENABLE_CHANGE, { specialKey, values });

export const setPendingIsEnabledChangeBulk = (
  specialKeys: string[],
  values: Array<
    Pick<
      Trigger,
      'duration' | 'message' | 'triggerType' | 'useForecast' | 'valueType' | 'hourCountStart' | 'window'
    > & {
      value: number | null;
    }
  >
) =>
  action(ActionTypes.SET_PENDING_IS_ENABLE_CHANGE_BULK, {
    specialKeys,
    values
  });

export const removePendingIsEnabledChange = (specialKey: string) =>
  action(ActionTypes.REMOVE_PENDING_IS_ENABLE_CHANGE, { specialKey });

export const enableTriggerRequest = (
  samplePointId: SamplePointId,
  eventLevel: EventLevel,
  conditionType: ConditionType,
  valueType: ValueType,
  values: Pick<
    Trigger,
    'duration' | 'message' | 'triggerType' | 'useForecast' | 'valueType'
  > & { value: number | null }
) =>
  action(ActionTypes.ENABLE_ALERT_TRIGGER_REQUEST, {
    samplePointId,
    eventLevel,
    conditionType,
    valueType,
    values
  });

export const enableTriggerSuccess = (id: number) =>
  action(ActionTypes.ENABLE_ALERT_TRIGGER_SUCCESS, { id });

export const enableTriggerFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.ENABLE_ALERT_TRIGGER_FAILURE,
    { message, error },
    undefined,
    true
  );

/** Create triggers in bulk */
export const enableTriggerBulk = (
  triggerOptions: Array<{
    samplePointId: SamplePointId;
    eventLevel: EventLevel;
    valueType: ValueType;
    conditionType: ConditionType;
    values: {
      value: number | null;
      duration?: number;
      message: string;
      triggerType: TriggerType;
      useForecast?: boolean;
      hourCountStart?: Trigger['hourCountStart'];
      window?: Trigger['window'];
    };
  }>
) =>
  action(ActionTypes.ENABLE_ALERT_TRIGGER_BULK_REQUEST, {
    triggerOptions
  });

export const enableTriggerBulkSuccess = (ids: number[]) =>
  action(ActionTypes.ENABLE_ALERT_TRIGGER_BULK_SUCCESS, { ids });

export const enableTriggerBulkFailure = (message: string, error?: AxiosError) =>
  action(
    ActionTypes.ENABLE_ALERT_TRIGGER_BULK_FAILURE,
    { message, error },
    undefined,
    true
  );

export const editTrigger = (id: TriggerId, values: TriggerEditableFields) =>
  action(ActionTypes.EDIT_TRIGGER_REQUEST, { id, values });

export const editTriggerSuccess = () => action(ActionTypes.EDIT_TRIGGER_SUCCESS);

export const editTriggerFailure = (message: string) =>
  action(ActionTypes.EDIT_TRIGGER_FAILURE, { message }, undefined, true);
