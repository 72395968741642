import { useEnterpriseCountry } from 'store/modules/enterprise/hooks';

export const SymbolToUnit = {
  cm: {
    name: {
      singular: 'Centimetre',
      plural: 'Centimetres'
    }
  },
  in: {
    name: {
      singular: 'Inch',
      plural: 'Inches'
    }
  },
  m: {
    name: {
      singular: 'Metre',
      plural: 'Metres'
    }
  },
  ft: {
    name: {
      singular: 'Foot',
      plural: 'Feet'
    }
  }
};

export enum UnitType {
  /**
   * cm or in
   */
  LEVEL_SM = 'LEVEL_SM',
  /**
   * metre or foot.
   * We don't name it as LEVEL_M as M could be mistaken for 'metre'.
   */
  LEVEL_MD = 'LEVEL_MD',
  /** flow per minute */
  FLOW = 'FLOW',
  /** flow per hour */
  FLOW_HOURLY = 'FLOW_HOURLY',
  /** litre or gallon */
  VOLUME_SM = 'VOLUME_SM',
  /** kilolitre or cubic-foot */
  VOLUME_MD = 'VOLUME_MD',
  /**
   * megalitre or acre-foot.
   * We don't name it as VOLUME_L as L could be mistaken for 'litre'.
   */
  VOLUME_LG = 'VOLUME_LG',
  /** cm/h or in/h */
  TREND_SM = 'TREND_SM',
  /** metre/h or ft/h */
  TREND_MD = 'TREND_MD',
  RAIN = 'RAIN',
  SIZE = 'SIZE',
  PRESSURE = 'PRESSURE',
  AREA_SM = 'AREA_SM',
  MATTER = 'MATTER'
}

interface GetUnitByCountryParams {
  unitType: UnitType;
  country: string;
}

type UnitAreaSm = 'm²' | 'ft²';
type UnitFlowMinute = 'L/min' | 'gal/min';
type UnitFlowHour = 'L/hr' | 'gal/hr';
type UnitRain = 'mm' | 'in';
type UnitTrendMd = 'm/h' | 'ft/h';
type UnitTrendSm = 'cm/h' | 'in/h';
export type UnitVolumeLg = 'ML' | 'ac. ft.';
export type UnitVolumeMd = 'KL' | 'ft³';
export type UnitVolumeSm = 'L' | 'gal';
export type UnitLevelMd = 'm' | 'ft';
export type UnitLevelSm = 'cm' | 'in';
export type UnitMatter = 'kg/ha' | 'lb/ac';
export type UnitPressure = 'kPa' | 'psi';
export type UnitSize = 'ha' | 'ac';

export type UnitSymbol =
  | UnitLevelSm
  | UnitLevelMd
  | UnitFlowMinute
  | UnitFlowHour
  | UnitVolumeSm
  | UnitVolumeMd
  | UnitVolumeLg
  | UnitTrendSm
  | UnitTrendMd
  | UnitRain
  | UnitSize
  | UnitPressure
  | UnitAreaSm
  | UnitMatter
  | '%';

type UnitTypeToUnitSymbol = {
  [UnitType.LEVEL_SM]: UnitLevelSm;
  [UnitType.LEVEL_MD]: UnitLevelMd;
  [UnitType.FLOW]: UnitFlowMinute;
  [UnitType.FLOW_HOURLY]: UnitFlowHour;
  [UnitType.VOLUME_SM]: UnitVolumeSm;
  [UnitType.VOLUME_MD]: UnitVolumeMd;
  [UnitType.VOLUME_LG]: UnitVolumeLg;
  [UnitType.TREND_SM]: UnitTrendSm;
  [UnitType.TREND_MD]: UnitTrendMd;
  [UnitType.RAIN]: UnitRain;
  [UnitType.SIZE]: UnitSize;
  [UnitType.PRESSURE]: UnitPressure;
  [UnitType.AREA_SM]: UnitAreaSm;
  [UnitType.MATTER]: UnitMatter;
};

/**
 * If getting unit in a React component, please use useGetLocaleUnit instead.
 * @returns Unit symbol
 */
export function getUnitByCountry<T extends UnitType>({
  unitType,
  country
}: GetUnitByCountryParams): UnitTypeToUnitSymbol[T] {
  const isUS = country === 'USA';
  // @uom-refactor to return a unit object which contains
  // both short & long name and symbol etc. And the unit can be used as a
  // type-safe parameter to construct a quantity object.
  switch (unitType) {
    case UnitType.LEVEL_SM:
      return (isUS ? 'in' : 'cm') as UnitTypeToUnitSymbol[T];
    case UnitType.LEVEL_MD:
      return (isUS ? 'ft' : 'm') as UnitTypeToUnitSymbol[T];
    case UnitType.FLOW:
      return (isUS ? 'gal/min' : 'L/min') as UnitTypeToUnitSymbol[T];
    case UnitType.FLOW_HOURLY:
      return (isUS ? 'gal/hr' : 'L/hr') as UnitTypeToUnitSymbol[T];
    case UnitType.VOLUME_SM:
      return (isUS ? 'gal' : 'L') as UnitTypeToUnitSymbol[T];
    case UnitType.VOLUME_MD:
      return (isUS ? 'ft³' : 'KL') as UnitTypeToUnitSymbol[T];
    case UnitType.VOLUME_LG:
      return (isUS ? 'ac. ft.' : 'ML') as UnitTypeToUnitSymbol[T];
    case UnitType.TREND_SM:
      return (isUS ? 'in/h' : 'cm/h') as UnitTypeToUnitSymbol[T];
    case UnitType.TREND_MD:
      return (isUS ? 'ft/h' : 'm/h') as UnitTypeToUnitSymbol[T];
    case UnitType.RAIN:
      return (isUS ? 'in' : 'mm') as UnitTypeToUnitSymbol[T];
    case UnitType.SIZE:
      return (isUS ? 'ac' : 'ha') as UnitTypeToUnitSymbol[T];
    case UnitType.PRESSURE:
      return (isUS ? 'psi' : 'kPa') as UnitTypeToUnitSymbol[T];
    case UnitType.AREA_SM:
      return (isUS ? 'ft²' : 'm²') as UnitTypeToUnitSymbol[T];
    case UnitType.MATTER:
      return (isUS ? 'lb/ac' : 'kg/ha') as UnitTypeToUnitSymbol[T];
    default:
      return 'cm' as UnitTypeToUnitSymbol[T];
  }
}

export function useGetLocaleUnit<T extends UnitType>(unitType: T): UnitTypeToUnitSymbol[T] {
  const country = useEnterpriseCountry();
  return getUnitByCountry({ unitType, country });
}
