import Checkbox, { CheckboxProps } from 'antd/lib/checkbox/Checkbox';
import React from 'react';

import './styles.less';

type Props = Pick<
  CheckboxProps,
  'checked' | 'children' | 'className' | 'onChange' | 'value'
>;

/**
 * A wrapper around the antd Button with our custom styling applied.
 */
function DefaultCheckbox({
  checked,
  children,
  className,
  onChange,
  value
}: Props): JSX.Element {
  return (
    <div className="DefaultCheckbox">
      <Checkbox
        checked={checked}
        className={className}
        onChange={onChange}
        value={value}
      >
        {children}
      </Checkbox>
    </div>
  );
}

export default DefaultCheckbox;
