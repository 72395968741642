import { message as antdMessage } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isNil from 'lodash/isNil';
import { REHYDRATE } from 'redux-persist';
import type { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import { triggerClient } from 'clients';
import rollbarLogger from 'config/rollbar';
import NetworkActionTypes from 'store/modules/network/constants';
import { selectNetworkOnline } from 'store/modules/network/selectors';
import { selectSamplePoints } from 'store/modules/samplePoints/selectors';
import { PendingIsEnabledTriggerChange, TriggersState } from 'store/modules/triggers/types';
import SamplePoint from 'types/models/samplePoint';
import Trigger from 'types/models/trigger';
import { APIResponseParsed } from 'types/response';
import { ConditionType, EventLevel, ValueType } from 'types/trigger.enum';
import makeTriggerSpecialKey from 'utils/make-trigger-special-key';
import {
  deleteRequest,
  patchRequest,
  postRequest
} from 'utils/redux-saga-requests';
import { spHasMultipleUnitTriggers } from 'utils/SamplePoints/sp-has-multiple-unit-triggers';
import { parseSpecialKey } from 'utils/Trigger/parse-special-key';

import {
  editTrigger,
  editTriggerFailure,
  editTriggerSuccess,
  enableTriggerBulk,
  enableTriggerBulkSuccess,
  enableTriggerRequest,
  enableTriggerSuccess,
  removePendingIsEnabledChange,
  removeTrigger,
  removeTriggerFailure,
  removeTriggers,
  setPendingIsEnabledChange,
  setPendingIsEnabledChangeBulk,
  setTrigger
} from './actions';
import ActionTypes from './constants';
import {
  selectEnabledTriggersKeyedBySpecialKey,
  selectPendingIsEnabledChanges
} from './selectors';
import { getCalculatedVolumeMappings } from '../volumeMappings/actions';
import { createVolumeMappingQueryFromTrigger } from '../volumeMappings/helper';
import { VolumeMappingQuery } from '../volumeMappings/types';

const createSpecialKeysForMultipleValueTypes = (
  valueTypes: ValueType[],
  samplePoint: SamplePoint,
  eventLevel: EventLevel,
  conditionType: ConditionType
): string[] => valueTypes.map((valType) => makeTriggerSpecialKey(
  samplePoint.id,
  eventLevel,
  valType,
  conditionType
));

/** Get trigger id by challenging special keys permutations */
function getTriggerIdFromSpecialKeys(
  samplePoint: SamplePoint,
  eventLevel: EventLevel,
  conditionType: ConditionType,
  triggers: Record<string, Trigger>
): number | undefined {
  if (spHasMultipleUnitTriggers(samplePoint)) {
    const valueTypesToTest: ValueType[] = [
      ValueType.REDUCED_LEVEL,
      ValueType.VOLUME,
      ValueType.VALUE
    ];
    const specialKeysToTest: string[] = createSpecialKeysForMultipleValueTypes(
      valueTypesToTest,
      samplePoint,
      eventLevel,
      conditionType
    );
    const availableSpecialKeys: string[] = intersection(
      Object.keys(triggers),
      specialKeysToTest
    );
    if (availableSpecialKeys.length === 1) {
      return triggers[availableSpecialKeys[0]].id;
    }
    // A rare case that should not happen. It means before we update the trigger, there're already more than one
    // triggers for the same type of trigger.
    if (availableSpecialKeys.length > 1) {
      antdMessage.error('Failed to save the trigger. Please refresh the page and delete the existing trigger.');
      rollbarLogger.error(`More than one triggers created for the same type of trigger. Special keys: ${availableSpecialKeys.join(', ')}`);
    }
  }
  return undefined;
}

export function* queueEnableAlertTrigger(
  action: ReturnType<typeof enableTriggerRequest>
) {
  const {
    payload: { samplePointId, eventLevel, valueType, conditionType, values }
  } = action;

  const specialKey = makeTriggerSpecialKey(
    samplePointId,
    eventLevel,
    valueType,
    conditionType
  );

  yield all([
    put(setPendingIsEnabledChange(specialKey, values)),
    put(enableTriggerSuccess(samplePointId))
  ]);
}

export function* watchEnableAlertTriggerRequest() {
  yield takeEvery(
    ActionTypes.ENABLE_ALERT_TRIGGER_REQUEST,
    queueEnableAlertTrigger
  );
}

export function* queueEnableAlertTriggerBulk(
  action: ReturnType<typeof enableTriggerBulk>
) {
  const {
    payload: { triggerOptions }
  } = action;

  // Iterate over triggerOptions, collecting 'specialKeys', trigger values &
  // samplePointIds in a single pass.
  const [specialKeys, triggerValues, samplePointIds] = triggerOptions.reduce(
    (
      [specialKeysAcc, valuesAcc, samplePointIdsAcc],
      { samplePointId, eventLevel, valueType, conditionType, values }
    ) => [
        [
          ...specialKeysAcc,
          makeTriggerSpecialKey(
            samplePointId,
            eventLevel,
            valueType,
            conditionType
          )
        ],
        [...valuesAcc, values],
        [...samplePointIdsAcc, samplePointId]
      ],
    [[], [], []] as [string[], any[], number[]]
  );

  yield all([
    put(setPendingIsEnabledChangeBulk(specialKeys, triggerValues)),
    put(enableTriggerBulkSuccess(samplePointIds))
  ]);
}

export function* watchEnableAlertTriggerBulkRequest() {
  yield takeEvery(
    ActionTypes.ENABLE_ALERT_TRIGGER_BULK_REQUEST,
    queueEnableAlertTriggerBulk
  );
}

// Edit triggers
export function* processEnabledTriggersUpdateQueue(): SagaIterator {
  const networkOnline: boolean | undefined = yield select(selectNetworkOnline);
  const samplePoints: Record<string, SamplePoint> =
    yield select(selectSamplePoints);
  const triggers: Record<string, Trigger> = yield select(
    selectEnabledTriggersKeyedBySpecialKey
  );

  const pendingIsEnabledChanges: TriggersState['pendingIsEnabledChanges'] =
    yield select(selectPendingIsEnabledChanges);

  const specialKeys: string[] = Object.keys(pendingIsEnabledChanges);

  if (networkOnline) {
    for (const specialKey of specialKeys) {
      const { samplePointId, eventLevel, valueType, conditionType } = parseSpecialKey(specialKey);
      const trigger: Trigger = triggers[specialKey];
      const samplePoint: SamplePoint = samplePoints[samplePointId];
      const triggerId: number | undefined = trigger?.id ?? getTriggerIdFromSpecialKeys(
        samplePoint,
        eventLevel,
        conditionType,
        triggers
      );
      const pendingChange: PendingIsEnabledTriggerChange = cloneDeep(pendingIsEnabledChanges[specialKey]);

      try {
        if (!triggerId && isNil(pendingChange.value)) {
          yield put(removePendingIsEnabledChange(specialKey));
          // eslint-disable-next-line no-continue
          continue;
        }
        const volumeMappingsQuery: VolumeMappingQuery = isNil(pendingChange.value)
          ? {}
          : createVolumeMappingQueryFromTrigger(
            pendingChange.value,
            pendingChange.valueType
          );

        if (triggerId && pendingChange.value === null) {
          // # Delete trigger
          yield call(deleteRequest, `trigger/${triggerId}`);
          yield put(removeTrigger({ triggerId, specialKey }));
        } else if (triggerId) {
          // # Update trigger
          const response: AxiosResponse<Trigger> = yield call(patchRequest, `trigger/${triggerId}`, {
            value: pendingChange.value,
            duration: pendingChange.duration,
            useForecast: pendingChange.useForecast,
            valueType: pendingChange.valueType,
            hourCountStart: pendingChange.hourCountStart,
            window: pendingChange.window
          });
          const { data } = response;
          const updatedTrigger: Trigger = {
            ...data,
            value: data.value
          };
          yield put(setTrigger({ trigger: updatedTrigger, specialKey }));
        } else {
          // # Add trigger
          // At the moment all the triggers have the same values for
          // thresholdSensitivity and timeBeforeReTrigger.
          // timeBeforeReTrigger is different for battery alerts, but we don't
          // create it from FE.
          const response: AxiosResponse<Trigger> = yield call(postRequest, 'trigger', {
            eventLevel: Number(eventLevel),
            isEnabled: true,
            timeBeforeReTrigger: 604800,
            samplePointId: Number(samplePointId),
            valueType,
            thresholdSensitivity: 5,
            hourCountStart: pendingChange.hourCountStart,
            window: pendingChange.window ?? 3600,
            duration: pendingChange.duration,
            conditionType: Number(conditionType),
            value: pendingChange.value,
            useForecast: pendingChange.useForecast,
            message: pendingChange.message,
            triggerType: pendingChange.triggerType
          });
          const { data } = response;
          const newTrigger: Trigger = {
            ...data,
            value: data.value
          };
          yield put(setTrigger({ trigger: newTrigger, specialKey }));
        }
        const spIsAdvancedDam: boolean = !!samplePoint?.config?.enabledVolumeMapping;
        if (spIsAdvancedDam && volumeMappingsQuery) {
          yield put(
            getCalculatedVolumeMappings(samplePoint.id, volumeMappingsQuery)
          );
        }
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;
        /**
         * Suppress error handling in the event of a Network Error.
         */
        if (error.message !== 'Network Error') {
          antdMessage.error(
            'Failed to update alert trigger. Please try again'
          );
          yield put(removePendingIsEnabledChange(specialKey));
        }
      }
    }
  }
}

export function* watchSetPendingIsEnableChange() {
  yield takeLatest(
    [
      REHYDRATE,
      NetworkActionTypes.SET_NETWORK_ONLINE,
      ActionTypes.SET_PENDING_IS_ENABLE_CHANGE,
      ActionTypes.SET_PENDING_IS_ENABLE_CHANGE_BULK
    ],
    processEnabledTriggersUpdateQueue
  );
}

export function* requestRemoveTriggers(
  action: ReturnType<typeof removeTriggers>
) {
  const {
    payload: { triggerIds }
  } = action;
  try {
    if (triggerIds.length) {
      yield all(triggerIds.map((id) => call(deleteRequest, `trigger/${id}`)));
    }
  } catch (error) {
    antdMessage.error('Failed to remove alert triggers');
    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(removeTriggerFailure(message, error as AxiosError));
  }
}

export function* watchRemoveTriggers() {
  yield takeLatest(ActionTypes.REMOVE_TRIGGERS, requestRemoveTriggers);
}

// Cannot reuse the processEnabledTriggersUpdateQueue above
// because LiquidFertiliserFlowRateTriggers can toggle between on and off states, without ever being deleted.
// The processEnabledTriggersUpdateQueue above processes only the 'enabledTriggers' in the store.
function* requestEditTrigger(action: ReturnType<typeof editTrigger>) {
  const { payload: { id, values } } = action;
  const response: APIResponseParsed<Trigger> = yield call(triggerClient.editTrigger, id, values);
  if (response.data) {
    yield all([
      put(setTrigger({ trigger: response.data, specialKey: '' })), // specialKey is not used because this function does not go through the pendingChange queue
      put(editTriggerSuccess())
    ]);
    antdMessage.success('Alert trigger updated');
  } else {
    const message = response.error.message || 'Failed to update alert trigger';
    yield put(editTriggerFailure(message));
    antdMessage.error('Failed to update alert trigger');
  }
}

function* watchEditTriggerRequest() {
  yield takeEvery(ActionTypes.EDIT_TRIGGER_REQUEST, requestEditTrigger);
}

export default function* triggersSaga() {
  yield all([
    fork(watchEnableAlertTriggerRequest),
    fork(watchEnableAlertTriggerBulkRequest),
    fork(watchSetPendingIsEnableChange),
    fork(watchRemoveTriggers),
    fork(watchEditTriggerRequest)
  ]);
}
