import axios from 'axios';

import Trigger, { TriggerEditableFields } from 'types/models/trigger';
import { APIResponseParsed } from 'types/response';
import { patchRequest } from 'utils/HttpRequest/http-request-utils';

import { parseAxiosError } from './utils/parse-axios-error';

async function editTrigger(triggerId: number, values: TriggerEditableFields): Promise<APIResponseParsed<Trigger>> {
  const path = `trigger/${triggerId}`;
  try {
    const response = await patchRequest<Trigger, TriggerEditableFields>(path, values);
    return { data: response.data, error: null };
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;
    return { data: null, error: parseAxiosError(error) };
  }
}

export default {
  editTrigger
};