import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import round from 'lodash/round';
import uniqWith from 'lodash/uniqWith';
import { createSelector } from 'reselect';

import {
  BORE_LEVEL_PRECISION,
  DAM_PRECISION,
  DEFAULT_PERCENTAGE_PRECISION,
  INTERMEDIARY_PRECISION,
  LINE_PRESSURE_PRECISION,
  TANK_LEVEL_PRECISION,
  TANK_VOLUME_PRECISION,
  TROUGH_DIRECT_DEFAULT_HEIGHT_CM,
  TROUGH_DIRECT_PRECISION
} from 'constants/samplePoint';
import { selectDevices } from 'store/modules/devices/selectors';
import { selectSamplePointsStatistics } from 'store/modules/samplePointsStatistics/selectors';
import { selectSites } from 'store/modules/sites/selectors';
import selectTime from 'store/modules/time/selectors';
import { ApplicationRootState } from 'store/types';
import { LatLong } from 'types/asset';
import { AssetTypeCode, AssetTypeId, RawAssetTypeCode } from 'types/models/asset-type';
import SamplePoint, {
  DamSamplePoint,
  MachineControl,
  SamplePointId,
  SamplePointWithNonNullableLocation,
  SensorTagId
} from 'types/models/samplePoint';
import {
  MachineControlStatistic,
  MergedSamplePointStatistic,
  SamplePointStatisticWithAggregates
} from 'types/models/samplePointsStatistic';
import { SiteId } from 'types/models/site';
import { DataType } from 'types/sample.enum';
import { calculateTankVolumeL } from 'utils/calculate-tank-volume-height';
import convertMetricToImperial, {
  convertMetricToImperialAlwaysRounding
} from 'utils/convert-metric-to-imperial';
import convertUnit, { Quantity } from 'utils/convert-unit';
import createDeepEqualSelector from 'utils/create-deep-equal-selector';
import { getSamplePointLocation } from 'utils/get-location';
import getSampleVolumePerMinute from 'utils/get-sample-volume-per-minute';
import { UnitLevelMd, UnitType, getUnitByCountry } from 'utils/get-unit-by-country';
import isSamplePointAlwaysConfigured from 'utils/is-sample-point-always-configured';
import percentage from 'utils/percentage';
import roundNumber from 'utils/round-number';
import { getDamLevel } from 'utils/Sample/get-dam-level';
import { getTankLevelForecast } from 'utils/Sample/get-tank-level-forecast';
import { getTankChangePerHour } from 'utils/SamplePoints/get-tank-change-per-hour';
import { isTroughDirect } from 'utils/SamplePoints/trough';
import { convertLitresTo } from 'utils/unit-conversions';

import {
  AutomaticControlType,
  MachineControlStatus
} from '../controlPoints/types';
import { selectCurrentEnterpriseCountry } from '../enterprise/selectors';
import { selectDefaultSite } from '../localUserSettings/selectors';

const selectSamplePointsState = (state: ApplicationRootState) =>
  state.samplePoints;

/** Locale sample point config and statistics */
const selectSamplePoints = createDeepEqualSelector(
  [
    selectSamplePointsState,
    selectSites,
    selectSamplePointsStatistics,
    selectDevices,
    selectTime,
    selectCurrentEnterpriseCountry
  ],
  (
    samplePointsState,
    sites,
    samplePointsStatistics,
    devices,
    currentDateInSeconds,
    country = 'AUS'
  ): Record<string, SamplePoint> =>
    mapValues(samplePointsState, (samplePoint) => {
      const isUS = country === 'USA';
      const { id, siteId, assetTypeId, deviceId, sensorTags } = samplePoint;
      const site = sites[siteId];
      const siteName = site?.name;
      const siteTimezoneCode = site?.timezoneCode;
      const coordinates = getSamplePointLocation(
        samplePoint,
        devices[deviceId]
      );
      const location = coordinates
        ? {
          coordinates
        }
        : undefined;

      const selectedSamplePoint = {
        ...samplePoint,
        // Don't remove the following line. It's for deep copy.
        config: {
          ...samplePoint.config
        },
        isConfigured:
          samplePoint.isConfigured ||
          isSamplePointAlwaysConfigured(samplePoint),
        siteName,
        siteTimezoneCode,
        location,
        device: pick(devices[deviceId], [
          'id',
          'name',
          'model',
          'status',
          'lastStatusDate',
          'statusCause',
          'serialNumber',
          'isBatteryLow'
        ])
      };

      let assetLastSampleDate: number | undefined;

      const samplePointsStatistic = samplePointsStatistics[id];

      const lastSample = samplePointsStatistic?.lastSample;
      const lastSampleDate = lastSample?.date || samplePoint.lastSampleDate;

      if (selectedSamplePoint.config.offset) {
        selectedSamplePoint.config.offset = convertMetricToImperial(
          UnitType.LEVEL_SM,
          country,
          selectedSamplePoint.config.offset
        );
      }

      // Store the most recent date of a sample point for tracking if data needs
      // to be re-requested
      if (
        !assetLastSampleDate ||
        (lastSampleDate && lastSampleDate > assetLastSampleDate)
      ) {
        assetLastSampleDate = lastSampleDate;
      }
      selectedSamplePoint.lastSampleDate = assetLastSampleDate;

      if (
        lastSample &&
        (assetTypeId === AssetTypeCode.WATER_TANK ||
          assetTypeId === AssetTypeCode.FUEL_TANK)
      ) {
        const localeMaxValue = convertMetricToImperialAlwaysRounding(
          UnitType.LEVEL_SM,
          country,
          samplePoint.config?.maxValue,
          TANK_LEVEL_PRECISION
        ) || 0;
        const localeTotalCapacity = convertMetricToImperialAlwaysRounding(
          UnitType.VOLUME_SM,
          country,
          samplePoint.config?.totalCapacity,
          TANK_VOLUME_PRECISION
        );
        const forecastedLevel = currentDateInSeconds ? getTankLevelForecast(
          lastSample,
          currentDateInSeconds,
          localeMaxValue,
          country
        ) : undefined;
        const forecastedVolumeL: number | undefined = !isNil(forecastedLevel) ? calculateTankVolumeL(
          forecastedLevel.metric,
          samplePoint.config?.maxValue,
          samplePoint.config?.totalCapacity,
          samplePoint.config,
          { decimalPlaces: INTERMEDIARY_PRECISION }
        ) : undefined;
        const localeForecastedVolume = convertMetricToImperialAlwaysRounding(
          UnitType.VOLUME_SM,
          country,
          forecastedVolumeL,
          TANK_VOLUME_PRECISION
        );
        let forecastedPercentFull;
        if (samplePoint.config.totalCapacity) {
          forecastedPercentFull = percentage(
            forecastedVolumeL,
            samplePoint.config.totalCapacity,
            { decimalPlaces: DEFAULT_PERCENTAGE_PRECISION }
          );
        } else {
          forecastedPercentFull = percentage(
            forecastedLevel?.metric,
            samplePoint.config.maxValue,
            { decimalPlaces: DEFAULT_PERCENTAGE_PRECISION }
          );
        }
        selectedSamplePoint.waterLevelStatistics = {
          changePerHour: getTankChangePerHour(lastSample, country),
          forecastedValue: forecastedLevel?.locale,
          forecastedPercentFull,
          maxValue: localeMaxValue,
          forecastedVolume: localeForecastedVolume,
          maxVolume: localeTotalCapacity
        };
        selectedSamplePoint.config.maxValue = localeMaxValue;
        selectedSamplePoint.config.totalCapacity = localeTotalCapacity;
        if (samplePoint.config?.tankLength) {
          selectedSamplePoint.config.tankLength = convertMetricToImperial(
            UnitType.LEVEL_SM,
            country,
            samplePoint.config?.tankLength
          );
        }
        if (samplePoint.config?.tankWidth) {
          selectedSamplePoint.config.tankWidth = convertMetricToImperial(
            UnitType.LEVEL_SM,
            country,
            samplePoint.config?.tankWidth
          );
        }
      }

      if (lastSample && assetTypeId === AssetTypeCode.LIQUID_FERTILISER_TANK) {
        const localeMaxValue = convertMetricToImperialAlwaysRounding(
          UnitType.LEVEL_SM,
          country,
          samplePoint.config.maxValue,
          TANK_LEVEL_PRECISION
        ) || 0;
        const localeTotalCapacity = convertMetricToImperialAlwaysRounding(
          UnitType.VOLUME_SM,
          country,
          samplePoint.config.totalCapacity,
          TANK_VOLUME_PRECISION
        );
        const localeLevel: number = convertMetricToImperialAlwaysRounding(
          UnitType.LEVEL_SM,
          country,
          lastSample.rwValue,
          TANK_LEVEL_PRECISION
        )!; // Won't be undefined because lastSample.rwValue is not undefined
        const forecastedLevel = currentDateInSeconds ? getTankLevelForecast(
          lastSample,
          currentDateInSeconds,
          localeMaxValue,
          country
        ) : undefined;
        const volumeL = calculateTankVolumeL(
          lastSample.rwValue,
          samplePoint.config.maxValue,
          samplePoint.config.totalCapacity,
          samplePoint.config,
          { decimalPlaces: INTERMEDIARY_PRECISION }
        );
        const localeVolume: number = convertMetricToImperialAlwaysRounding(
          UnitType.VOLUME_SM,
          country,
          volumeL,
          TANK_VOLUME_PRECISION
        )!; // Won't be undefined because volumeL is not undefined
        const forecastedVolumeL: number | undefined = !isNil(forecastedLevel) ? calculateTankVolumeL(
          forecastedLevel.metric,
          samplePoint.config?.maxValue,
          samplePoint.config?.totalCapacity,
          samplePoint.config
        ) : undefined;
        const localeForecastedVolume = convertMetricToImperialAlwaysRounding(
          UnitType.VOLUME_SM,
          country,
          forecastedVolumeL,
          TANK_VOLUME_PRECISION
        );
        selectedSamplePoint.liquidFertiliserStatistics = {
          level: forecastedLevel?.locale ?? localeLevel,
          height: localeMaxValue ?? 0,
          volume: localeForecastedVolume ?? localeVolume,
          maxVolume: localeTotalCapacity,
          capacity: percentage(forecastedVolumeL ?? volumeL, samplePoint.config.totalCapacity),
          changePerHour: getTankChangePerHour(lastSample, country)
        };
        selectedSamplePoint.config.maxValue = localeMaxValue;
        selectedSamplePoint.config.totalCapacity = localeTotalCapacity;
      }

      if (lastSample && assetTypeId === AssetTypeCode.PIPE) {
        const aggregates = (
          samplePointsStatistic as SamplePointStatisticWithAggregates
        )?.aggregates;
        const aggregatesOneDay = convertMetricToImperial(
          UnitType.VOLUME_SM,
          country,
          aggregates?.oneDay?.values?.sum
        );
        const aggregatesSevenDays = convertMetricToImperial(
          UnitType.VOLUME_SM,
          country,
          aggregates?.sevenDays?.values?.sum
        );
        const aggregatesThirtyDays = convertMetricToImperial(
          UnitType.VOLUME_SM,
          country,
          aggregates?.thirtyDays?.values?.sum
        );

        selectedSamplePoint.flowStatistics = {
          current: getSampleVolumePerMinute(lastSample, country),
          oneDay: roundNumber(aggregatesOneDay, { decimalPlaces: 0 }),
          sevenDays: roundNumber(aggregatesSevenDays, { decimalPlaces: 0 }),
          thirtyDays: roundNumber(aggregatesThirtyDays, { decimalPlaces: 0 })
        };
      }

      if (lastSample && assetTypeId === AssetTypeCode.LINE_PRESSURE) {
        const maxValue = selectedSamplePoint.config?.maxValue;
        if (maxValue !== undefined) {
          selectedSamplePoint.config.maxValue = convertMetricToImperial(
            UnitType.PRESSURE,
            country,
            maxValue,
            LINE_PRESSURE_PRECISION
          );
        }
        const currentPressure = convertMetricToImperialAlwaysRounding(
          UnitType.PRESSURE,
          country,
          lastSample.rwValue,
          LINE_PRESSURE_PRECISION
        ) as number;
        selectedSamplePoint.linePressureStatistics = {
          currentCapacity:
            maxValue && maxValue > 0
              ? roundNumber((lastSample.rwValue * 100) / maxValue)
              : undefined,
          currentPressure
        };
      }

      if (lastSample && assetTypeId === AssetTypeCode.RAIN_GAUGE) {
        const aggregates = (
          samplePointsStatistic as SamplePointStatisticWithAggregates
        )?.aggregates;
        const today = convertMetricToImperial(
          UnitType.RAIN,
          country,
          aggregates?.today?.values?.sum
        );
        const mtd = convertMetricToImperial(
          UnitType.RAIN,
          country,
          aggregates?.mtd?.values?.sum
        );
        const ytd = convertMetricToImperial(
          UnitType.RAIN,
          country,
          aggregates?.ytd?.values?.sum
        );
        const rolling90Days = convertMetricToImperial(
          UnitType.RAIN,
          country,
          aggregates?.rolling90Days?.values?.sum
        );
        const decimalPlaces = isUS ? 2 : 1;
        selectedSamplePoint.rainfallStatistics = {
          today: round(today ?? 0, decimalPlaces),
          mtd: round(mtd ?? 0, decimalPlaces),
          ytd: round(ytd ?? 0, decimalPlaces),
          rolling90Days: round(rolling90Days ?? 0, decimalPlaces)
        };
      }

      if (lastSample && sensorTags.id === SensorTagId.TROUGH) {
        const decimalPlaces = isUS ? 1 : 0;
        const aggregates = (
          samplePointsStatistic as SamplePointStatisticWithAggregates
        )?.aggregates;
        const aggregatesHighestAverageThisFortnight = convertMetricToImperial(
          UnitType.LEVEL_SM,
          country,
          aggregates?.thisFortnight.values.highestAverage
        );
        const aggregatesMinimumThisFortnight = convertMetricToImperial(
          UnitType.LEVEL_SM,
          country,
          aggregates?.thisFortnight?.values.lowestMinimum
        );

        selectedSamplePoint.config.maxValue =
          convertMetricToImperial(
            UnitType.LEVEL_SM,
            country,
            selectedSamplePoint.config.maxValue
          ) || 0;

        selectedSamplePoint.troughStatistics = {
          highestAverageThisFortnight: roundNumber(
            aggregatesHighestAverageThisFortnight,
            { decimalPlaces }
          ),
          minimumThisFortnight: roundNumber(aggregatesMinimumThisFortnight, {
            decimalPlaces
          }),
          latestAverage: convertMetricToImperial(
            UnitType.LEVEL_SM,
            country,
            lastSample.multiDimValues?.sampleDim?.find(
              (s) => s.dataType === DataType.AVERAGE
            )?.rwValue
          ),
          latestMinimum: convertMetricToImperial(
            UnitType.LEVEL_SM,
            country,
            lastSample.multiDimValues?.sampleDim?.find(
              (s) => s.dataType === DataType.MINIMUM
            )?.rwValue
          )
        };
      }

      if (lastSample && isTroughDirect(samplePoint)) {
        const configuredHeight = selectedSamplePoint.config.maxValue;
        const autoCalibratedHeight = lastSample.extraValues.maxLevel;

        // maxValue is default to 2 decimal places for later calculations (e.g., trigger value)
        selectedSamplePoint.config.maxValue = convertMetricToImperialAlwaysRounding(
          UnitType.LEVEL_SM,
          country,
          configuredHeight,
          2
        );
        selectedSamplePoint.config.maxValueAutoCalibrated = convertMetricToImperialAlwaysRounding(
          UnitType.LEVEL_SM,
          country,
          autoCalibratedHeight,
          2
        );
        selectedSamplePoint.config.totalCapacity = convertMetricToImperialAlwaysRounding(
          UnitType.VOLUME_SM,
          country,
          selectedSamplePoint.config.totalCapacity,
          0
        );
        selectedSamplePoint.troughDirectStatistics = {
          level: {
            value: convertMetricToImperialAlwaysRounding(
              UnitType.LEVEL_SM,
              country,
              lastSample.rwValue,
              TROUGH_DIRECT_PRECISION) ?? 0,
            unit: {
              symbol: getUnitByCountry({
                unitType: UnitType.LEVEL_SM,
                country
              }) as 'cm' | 'in'
            }
          },
          capacity: {
            value: percentage(
              lastSample.rwValue,
              configuredHeight ?? autoCalibratedHeight ?? TROUGH_DIRECT_DEFAULT_HEIGHT_CM
            ),
            unit: {
              symbol: '%'
            }
          }
        };
      }

      if (lastSample && assetTypeId === AssetTypeCode.SOIL) {
        const aggregateValues = (
          samplePointsStatistic as SamplePointStatisticWithAggregates
        ).aggregates?.thisFortnight?.values;

        selectedSamplePoint.soilStatistics = {
          maxMoistureThisFortnight: roundNumber(
            aggregateValues?.highestAverageMoisture
          ),
          minMoistureThisFortnight: roundNumber(
            aggregateValues?.lowestMinimumMoisture
          ),
          maxTempThisFortnight: roundNumber(
            aggregateValues?.highestAverageTemp
          ),
          minTempThisFortnight: roundNumber(aggregateValues?.lowestMinimumTemp),
          latestMoisture: roundNumber(
            lastSample.multiDimValues?.sampleDim?.find(
              (s) => s.dataType === RawAssetTypeCode.SOIL_MOISTURE
            )?.rwValue
          ),
          latestTemp: roundNumber(
            lastSample.multiDimValues?.sampleDim?.find(
              (s) => s.dataType === RawAssetTypeCode.SOIL_TEMP
            )?.rwValue
          )
        };
      }

      if (lastSample && assetTypeId === AssetTypeCode.DAM) {
        const {
          levelDisplayUnit,
          volumeDisplayUnit,
          liquidLevelMeasurementDirection,
          maxDepth: maxDepthCm
        } = selectedSamplePoint.config as DamSamplePoint['config'];
        const damIsAdvanced = selectedSamplePoint.config?.enabledVolumeMapping;
        const levelCm = lastSample.rwValue;
        const levelLocale = getDamLevel(
          levelCm,
          maxDepthCm,
          liquidLevelMeasurementDirection,
          levelDisplayUnit
        );
        const maxDepthQuantity: Quantity = { value: maxDepthCm ?? 0, unit: { symbol: 'cm' } };
        const maxDepthLocale: number = convertUnit(
          maxDepthQuantity,
          { symbol: levelDisplayUnit },
          DAM_PRECISION
        );
        const maxVolume =
          convertLitresTo(selectedSamplePoint.config?.maxVolume, volumeDisplayUnit) ?? 0;
        const simpleDamVolume = maxDepthCm ? maxVolume * (levelCm / maxDepthCm) : 0;
        const advancedDamVolume =
          convertLitresTo(lastSample?.volume, volumeDisplayUnit) ?? 0;
        let capacity: number;

        if (damIsAdvanced && maxVolume) {
          capacity = percentage(advancedDamVolume, maxVolume);
        } else {
          capacity = maxDepthCm ? percentage(levelCm, maxDepthCm) : 0;
        }
        const reducedLevelUnit = getUnitByCountry({
          unitType: UnitType.LEVEL_MD,
          country
        }) as UnitLevelMd;
        const reducedLevelLocale = convertUnit({
          value: lastSample?.levelRl ?? 0,
          unit: { symbol: 'm' }
        },
          { symbol: reducedLevelUnit },
          DAM_PRECISION
        );
        selectedSamplePoint.damStatistics = {
          changePerHour: getTankChangePerHour(lastSample, country, UnitType.TREND_MD),
          level: levelLocale ?? 0,
          volume: damIsAdvanced ? advancedDamVolume : simpleDamVolume,
          maxVolume,
          maxDepth: maxDepthLocale,
          capacity,
          reducedLevel: damIsAdvanced ? reducedLevelLocale : undefined
        };
      }

      if (lastSample && assetTypeId === AssetTypeCode.BORE) {
        const localeMaxDepth = convertMetricToImperial(
          UnitType.LEVEL_MD,
          country,
          selectedSamplePoint.config?.maxDepth
        );
        const localeLevel = convertMetricToImperial(
          UnitType.LEVEL_MD,
          country,
          lastSample.rwValue
        );
        selectedSamplePoint.boreStatistics = {
          level:
            localeMaxDepth && localeLevel !== undefined
              ? round(localeMaxDepth - localeLevel, BORE_LEVEL_PRECISION)
              : undefined
        };
      }

      if (lastSample && assetTypeId === AssetTypeCode.SAFETY_CHECK_IN) {
        let sosDate;
        const sosStatistics = (
          samplePointsStatistic as MergedSamplePointStatistic
        )._hidden;
        if (sosStatistics) {
          sosDate = sosStatistics.lastSample?.date;
        }
        selectedSamplePoint.safetyCheckInStatistics = {
          checkInDate: lastSample?.date,
          sosDate
        };
      }

      if (
        samplePointsStatistic &&
        assetTypeId === AssetTypeCode.MACHINE_CONTROL
      ) {
        let connectedSamplePointCount: MachineControlStatistic['connectedSamplePointCount'] = 0;
        if ((samplePointsStatistic as MachineControlStatistic).sourceSamplePoint?.sid) {
          connectedSamplePointCount++;
        }
        if ((samplePointsStatistic as MachineControlStatistic).destinationSamplePoint?.sid) {
          connectedSamplePointCount++;
        }
        const parsedStatus = {
          [MachineControlStatus.OFF]: 'Power is off',
          [MachineControlStatus.ON]: 'Power is on',
          [MachineControlStatus.TURNING_OFF]: 'Turning off',
          [MachineControlStatus.TURNING_ON]: 'Turning on',
          [MachineControlStatus.ERROR]: 'Error'
        };
        const parsedVerboseAutomation = {
          [AutomaticControlType.MANUAL]: 'Manual',
          [AutomaticControlType.AUTO]: 'Auto Enabled',
          [AutomaticControlType.AUTO_PAUSED]: 'Auto Paused'
        };
        selectedSamplePoint.machineControlStatistics = {
          ...(samplePointsStatistic as MachineControlStatistic),
          connectedSamplePointCount: connectedSamplePointCount as MachineControlStatistic['connectedSamplePointCount'],
          verboseStatus:
            parsedStatus[
            (samplePointsStatistic as MachineControlStatistic).status ??
            MachineControlStatus.ERROR
            ],
          verboseAutomation:
            parsedVerboseAutomation[
            (samplePointsStatistic as MachineControlStatistic).automation
            ]
        };
      }

      return selectedSamplePoint;
    })
);

const selectSamplePointsAsArray = createSelector(
  selectSamplePoints,
  (samplePoints) => orderBy(Object.values(samplePoints), 'name')
);

const makeSelectSamplePointsByDevice = (deviceId: number) =>
  createSelector(selectSamplePoints, (samplePoints) =>
    Object.values(samplePoints).filter((s) => s.device?.id === deviceId)
  );

const selectSamplePointsGroupedByAssetTypeId = createDeepEqualSelector(
  selectSamplePointsAsArray,
  (samplePointsAsArray) => groupBy(samplePointsAsArray, 'assetTypeId')
);

const selectUsedAssetTypeIds = createDeepEqualSelector(
  selectSamplePointsGroupedByAssetTypeId,
  (samplePointsGroupedByAssetTypeId) =>
    Object.keys(samplePointsGroupedByAssetTypeId).map<AssetTypeId>(Number)
);

const makeSelectSamplePointsByAssetTypeId = (assetTypeId: AssetTypeId) =>
  createSelector(
    selectSamplePointsGroupedByAssetTypeId,
    (samplePointsGroupedByAssetTypeId) =>
      samplePointsGroupedByAssetTypeId[assetTypeId]
  );

const selectSamplePointsGroupedByAssetTypeIdForDefaultSite = createSelector(
  [selectSamplePointsGroupedByAssetTypeId, selectDefaultSite],
  (samplePointsGroupedByAssetTypeId, defaultSite) =>
    mapValues(samplePointsGroupedByAssetTypeId, (samplePoints) =>
      samplePoints.filter(({ siteId }) => siteId === defaultSite?.id)
    )
);

const makeSelectSamplePointsByAssetTypeIdForDefaultSite = (
  assetTypeId: AssetTypeId
) =>
  createSelector(
    [makeSelectSamplePointsByAssetTypeId(assetTypeId), selectDefaultSite],
    (samplePoints, defaultSite) =>
      samplePoints?.filter(({ siteId }) => siteId === defaultSite?.id) || []
  );

/**
 * Get the sample point straight from store
 * TODO: FMBT-4049 add internal sample points into sample point state.
 */
const makeSelectRawSamplePointById = (id?: SamplePointId) =>
  createSelector(selectSamplePointsState, (samplePoints) => id ? samplePoints[id] : undefined);

const makeSelectSamplePointById = (samplePointId: SamplePointId) =>
  createSelector(
    selectSamplePoints,
    (samplePoints) => samplePoints[samplePointId] as SamplePoint | undefined
  );

// TODO: we shouldn't memoize basic finds, only when there are mutations
// TODO: params for selects should never be optional
const makeSelectSamplePointBySid = (sid?: string) =>
  createSelector(selectSamplePointsAsArray, (samplePoints) =>
    samplePoints.find((sp) => sp.sid === sid)
  );

/**
 * Returns sample points that have these sids, though the order is by the id in the store, not
 * the sids passed in as argument
 *
 * WARNING: the SamplePoint returned by this selector will miss some properties
 * compared to the one returned by makeSelectSamplePointById, such as siteTimezoneCode
 * is not readily mapped from site.timezoneCode
 */
const makeSelectSamplePointsBySids =
  (sids: string[]) => (state: ApplicationRootState) => {
    return Object.values(state.samplePoints).filter((sp) =>
      sids.some((sid) => sid === sp.sid)
    );
  };

const makeSelectPumpControlsByConnectedSids =
  (sids: string[]) => (state: ApplicationRootState) => {
    return Object.values(state.samplePoints)
      .filter((sp) => {
        const isPumpControlWithConnectedSamplePoints = sp.assetTypeId === AssetTypeCode.MACHINE_CONTROL
          && (sp as MachineControl).machineControlStatistics?.connectedSamplePointCount > 0;
        if (isPumpControlWithConnectedSamplePoints) {
          return sids.some((sid) =>
            sid === (sp as MachineControl).machineControlStatistics.destinationSamplePoint?.sid
            || sid === (sp as MachineControl).machineControlStatistics.sourceSamplePoint?.sid
          );
        }
        return false;
      }
      );
  };

const makeSelectSamplePointExists = (samplePointId: SamplePointId) =>
  createSelector(
    selectSamplePoints,
    (samplePoints) => !!samplePoints[samplePointId]
  );

const makeSelectSamplePointsCoordinates = (siteId: SiteId) =>
  createDeepEqualSelector(selectSamplePointsAsArray, (samplePointsAsArray) => {
    const resultWithPossibleDups = samplePointsAsArray
      .filter((sp) => sp.siteId === siteId)
      .map(({ location }) => location?.coordinates)
      .filter((coordinates): coordinates is LatLong => Boolean(coordinates));

    const uniqueResults = uniqWith(
      resultWithPossibleDups,
      ([x, y], [x2, y2]) => x === x2 && y === y2
    );

    return orderBy(uniqueResults, '[1]', 'desc');
  });

const selectSamplePointsGroupedByLocation =
  createDeepEqualSelector(selectSamplePointsAsArray, (samplePointsAsArray) =>
    groupBy(samplePointsAsArray, 'location.coordinates')
  );

const makeSelectSamplePointsByLocation = (coordinatesString: string) =>
  createDeepEqualSelector(
    selectSamplePointsGroupedByLocation,
    (groupedSamplePoints) => groupedSamplePoints[coordinatesString] as SamplePointWithNonNullableLocation[] || []
  );

const makeSelectIsAdvancedDam = (samplePointId: SamplePointId) =>
  createSelector(
    selectSamplePoints,
    (samplePoints) => samplePoints[samplePointId]?.config?.enabledVolumeMapping
  );

export {
  makeSelectIsAdvancedDam,
  makeSelectPumpControlsByConnectedSids,
  makeSelectRawSamplePointById,
  makeSelectSamplePointById,
  makeSelectSamplePointBySid,
  makeSelectSamplePointExists,
  makeSelectSamplePointsByAssetTypeId,
  makeSelectSamplePointsByAssetTypeIdForDefaultSite,
  makeSelectSamplePointsByDevice,
  makeSelectSamplePointsBySids,
  makeSelectSamplePointsByLocation,
  makeSelectSamplePointsCoordinates,
  selectSamplePoints,
  selectSamplePointsAsArray,
  selectSamplePointsGroupedByAssetTypeIdForDefaultSite,
  selectSamplePointsState,
  selectUsedAssetTypeIds
};
