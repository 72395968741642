enum ActionTypes {
  SET_TRIGGERS = 'SET_TRIGGERS',
  SET_TRIGGER = 'SET_TRIGGER',
  REMOVE_TRIGGER = 'REMOVE_TRIGGER',
  REMOVE_TRIGGERS = 'REMOVE_TRIGGERS',
  REMOVE_TRIGGER_FAILURE = 'REMOVE_TRIGGER_FAILURE',
  SET_PENDING_IS_ENABLE_CHANGE = 'SET_PENDING_IS_ENABLE_CHANGE',
  SET_PENDING_IS_ENABLE_CHANGE_BULK = 'SET_PENDING_IS_ENABLE_CHANGE_BULK',
  REMOVE_PENDING_IS_ENABLE_CHANGE = 'REMOVE_PENDING_IS_ENABLE_CHANGE',
  ENABLE_ALERT_TRIGGER_REQUEST = 'ENABLE_ALERT_TRIGGER_REQUEST',
  ENABLE_ALERT_TRIGGER_SUCCESS = 'ENABLE_ALERT_TRIGGER_SUCCESS',
  ENABLE_ALERT_TRIGGER_FAILURE = 'ENABLE_ALERT_TRIGGER_FAILURE',
  ENABLE_ALERT_TRIGGER_BULK_REQUEST = 'ENABLE_ALERT_TRIGGER_BULK_REQUEST',
  ENABLE_ALERT_TRIGGER_BULK_SUCCESS = 'ENABLE_ALERT_TRIGGER_BULK_SUCCESS',
  ENABLE_ALERT_TRIGGER_BULK_FAILURE = 'ENABLE_ALERT_TRIGGER_BULK_FAILURE',
  EDIT_TRIGGER_REQUEST = 'EDIT_TRIGGER_REQUEST',
  EDIT_TRIGGER_SUCCESS = 'EDIT_TRIGGER_SUCCESS',
  EDIT_TRIGGER_FAILURE = 'EDIT_TRIGGER_FAILURE'
}

export default ActionTypes;
